import { checkNoAds } from "@/advertising/ad-blocker";

// const visibility = (isVisible: boolean) => {
//     return { display: isVisible ? "block" : "none" };
// };

// type FreeEbookBannerProps = {
//     // This overrides the default visibility, which is based on the ad
//     // preferences.
//     isVisibleOverride?: boolean;
// };

export default function FreeEbookBanner() {
    const noads = checkNoAds();
    if (noads) {
        return <></>;
    }
    return (
        <>
            <aside className="p-5 bg-primary">
                <div className="container py-5">
                    {/* <h1 className="display-5 fw-bold">Custom jumbotron</h1> */}
                    <h2 className="text-white">Download our free ebook!</h2>
                    <p className="text-white col-md-8 fs-5 fw-light">
                        Just click the "Sign up" button below to create an
                        account, and we'll send you a free PDF ebook with tips
                        on how to get started with memory techniques.
                    </p>
                    <a
                        href="https://forum.artofmemory.com/signup"
                        rel="nofollow noopener noreferrer"
                        target="_blank"
                        className="btn btn-light btn-lg"
                    >
                        Sign up
                    </a>
                </div>
            </aside>
        </>
    );
}
